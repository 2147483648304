<template>
  <b-row class="match-height">
    <b-col
      lg="12"
      md="12"
    >
      <profile-header :data-profile="selfProfile" />
    </b-col>

    <b-col
      lg="3"
      cols="12"
      order="2"
      order-lg="1"
    >
      <profile-left :data-profile="selfProfile" />
      <profile-ciri :data-profile="selfProfile" />
    </b-col>

    <b-col
      lg="6"
      cols="12"
      order="1"
      order-lg="2"
    >
      <profile-mid :data-profile="selfProfile" />
    </b-col>
    <b-col
      lg="3"
      cols="12"
      order="3"
    >
      <profile-right :data-profile="selfProfile" />
      <profile-pensiun :data-profile="selfProfile" />
    </b-col>
    <b-col
      cols="12"
      order="4"
    >
      <history-widget :data-profile="selfProfile">
        <template v-slot:judul>
          Riwayat Pendidikan
        </template>
      </history-widget>
      <history-widget :data-profile="selfProfile">
        <template v-slot:judul>
          Riwayat Diklat
        </template>
      </history-widget>
      <history-widget :data-profile="selfProfile">
        <template v-slot:judul>
          Riwayat Penghargaan
        </template>
      </history-widget>
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import router from '@/router'
import ProfileHeader from '@/views/components/profile-pegawai/Header.vue'
import ProfileLeft from '@/views/components/profile-pegawai/Left.vue'
import ProfileCiri from '@/views/components/profile-pegawai/LeftCiriPribadi.vue'
import ProfileMid from '@/views/components/profile-pegawai/Mid.vue'
import ProfileRight from '@/views/components/profile-pegawai/Right.vue'
import ProfilePensiun from '@/views/components/profile-pegawai/RightAlamatPensiun.vue'
import HistoryWidget from '@/views/components/profile-pegawai/HistoryWidget.vue'
import RepositoryHrisDjpb from '@/repositories/factories/hrisDjpbFactory'

const pegawaiProfile = RepositoryHrisDjpb.get('pegawaiProfile')
const pegawaiGrade = RepositoryHrisDjpb.get('pegawaiGrade')

export default {
  components: {
    BRow,
    BCol,
    ProfileHeader,
    ProfileLeft,
    ProfileCiri,
    ProfileMid,
    ProfileRight,
    ProfilePensiun,
    HistoryWidget,
  },
  data() {
    return {
      pegawaiProfile: {},
      pegawaiGrade: {},
      selfProfile: {
        foto: 'pegawai_foto',
      },
    }
  },
  computed: {
    ...mapGetters(['thisPegawaiProfile']),
  },
  watch: {
    pegawaiProfile(newVal) {
      this.selfProfile = { ...newVal, ...this.pegawaiGrade }
    },
    pegawaiGrade(newVal) {
      this.selfProfile = { ...newVal, ...this.pegawaiProfile }
    },
  },
  created() {
    this.getPegawaiProfile()
    this.getPegawaiGrade()
  },
  mounted() {
    this.$nextTick(() => {
      // this.getPegawaiProfile()
    })
  },
  methods: {
    async getPegawaiProfile() {
      try {
        const { data } = await pegawaiProfile.callproc(`${router.currentRoute.params.nip}`)
        const isDataAvailable = data && data.resource.length
        const dataResource = isDataAvailable ? data.resource[0] : ''
        this.pegawaiProfile = dataResource
      } catch (err) {
        console.log(err)
      }
    },
    async getPegawaiGrade() {
      try {
        const param = {
          nip: router.currentRoute.params.nip,
          jenis: 'lengkap',
        }
        const { data } = await pegawaiGrade.callproc(JSON.stringify(param))
        const isDataAvailable = data && data.resource.length
        const dataResource = isDataAvailable ? data.resource[0] : ''
        this.pegawaiGrade = dataResource
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>
