<template>
  <b-card
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title><slot name="judul">
        Data Riwayat
      </slot></b-card-title>
      <feather-icon
        icon="MoreVerticalIcon"
        size="18"
        class="cursor-pointer"
      />
    </b-card-header>

    <b-card-body>
      <div
        v-for="riwayat in riwayatData"
        :key="riwayat.id"
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              :variant="riwayat.avatarVariant"
            >
              <feather-icon
                size="18"
                :icon="riwayat.avatar"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              {{ riwayat.jenis }}
            </h6>
            <small>{{ riwayat.uraian }}</small>
            <p>{{ riwayat.keterangan }}</p>
          </b-media-body>

        </b-media>

        <small>
          TMT. {{ riwayat.tanggal }}</small>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
  },
  data() {
    return {
      riwayatData: [
        {
          id: '1',
          jenis: 'Pengangkatan Menjadi Calon Pegawai Negeri Sipil (CPNS)',
          uraian: 'KM-829/SJ.2.2/UP.1/1999 / 31-03-1999',
          keterangan: 'Pengatur Muda Tingkat I (II/b)',
          avatar: 'ListIcon',
          avatarVariant: 'light-info',
          tanggal: '01-01-2021',
          deduction: false,
        },
        {
          id: '2',
          jenis: 'Pengangkatan Menjadi Pegawai Negeri Sipil (PNS)',
          uraian: 'KM-829/SJ.2.2/UP.1/1999 / 31-03-1999',
          keterangan: 'Pengatur Muda Tingkat I (II/b)',
          avatar: 'ListIcon',
          avatarVariant: 'light-info',
          tanggal: '01-01-2021',
          deduction: false,
        },
        {
          id: '3',
          jenis: 'Kenaikan Pangkat Pilihan',
          uraian: 'KM-829/SJ.2.2/UP.1/1999 / 31-03-1999',
          keterangan: 'Pengatur Muda Tingkat I (II/b)',
          avatar: 'ListIcon',
          avatarVariant: 'light-info',
          tanggal: '01-01-2021',
          deduction: false,
        },
        {
          id: '4',
          jenis: 'Kenaikan Pangkat Reguler',
          uraian: 'KM-829/SJ.2.2/UP.1/1999 / 31-03-1999',
          keterangan: 'Pengatur Muda Tingkat I (II/b)',
          avatar: 'ListIcon',
          avatarVariant: 'light-info',
          tanggal: '01-01-2021',
          deduction: false,
        },
        {
          id: '5',
          jenis: 'Kenaikan Pangkat Reguler',
          uraian: 'KM-829/SJ.2.2/UP.1/1999 / 31-03-1999',
          keterangan: 'Pengatur Muda Tingkat I (II/b)',
          avatar: 'ListIcon',
          avatarVariant: 'light-info',
          tanggal: '01-01-2021',
          deduction: false,
        },
        {
          id: '6',
          jenis: 'Kenaikan Pangkat Reguler',
          uraian: 'KM-829/SJ.2.2/UP.1/1999 / 31-03-1999',
          keterangan: 'Pengatur Muda Tingkat I (II/b)',
          avatar: 'ListIcon',
          avatarVariant: 'light-info',
          tanggal: '01-01-2021',
          deduction: false,
        },
      ],
    }
  },
}
</script>
