<template>
  <b-card>
    <!-- about
    <div
      v-for="(data,key,index) in dataProfile in 10"
      :key="data.nip"
      :class="index ? 'mt-2':''"
    > -->
    <b-card-title>Data Kepegawaian</b-card-title>
    <div class="mt-2">
      <h5 class="text-capitalize mb-75">
        NIP
      </h5>
      <b-card-text>
        {{ dataProfile.nip }}
      </b-card-text>
    </div>
    <div class="mt-2">
      <h5 class="text-capitalize mb-75">
        Pangkat
      </h5>
      <b-card-text>
        {{ dataProfile.gol_nama }} ({{ dataProfile.gol_ruang }}) TMT. {{ dataProfile.tmt_golongan }}
      </b-card-text>
    </div>
    <div class="mt-2">
      <h5 class="text-capitalize mb-75">
        Status Kepegawaian
      </h5>
      <b-card-text>
        {{ dataProfile.uraian_status }}
      </b-card-text>
    </div>
    <div class="mt-2">
      <h5 class="text-capitalize mb-75">
        Tanggal Pensiun
      </h5>
      <b-card-text>
        {{ this.$moment(dataProfile.tanggal_pensiun).format('DD MMMM YYYY') }}
      </b-card-text>
    </div>
    <div class="mt-2">
      <h5 class="text-capitalize mb-75">
        Nomor Karpeg
      </h5>
      <b-card-text>
        {{ dataProfile.nomor_karpeg }}
      </b-card-text>
    </div>
    <div class="mt-2">
      <h5 class="text-capitalize mb-75">
        Nomor NPWP
      </h5>
      <b-card-text>
        {{ dataProfile.nomor_npwp | FormatNpwp }}
      </b-card-text>
    </div>
    <div class="mt-2">
      <h5 class="text-capitalize mb-75">
        Masa Kerja
      </h5>
      <b-card-text>
        {{ dataProfile.masa_kerja }}
      </b-card-text>
    </div>
    <div class="mt-2">
      <h5 class="text-capitalize mb-75">
        Eselon
      </h5>
      <b-card-text>
        {{ dataProfile.uraian_eselon | TitleCase }} (TMT. {{ this.$moment(dataProfile.tmt_eselon).format('DD MMMM YYYY') }})
      </b-card-text>
    </div>
    <div class="mt-2">
      <h5 class="text-capitalize mb-75">
        Jabatan Terakhir
      </h5>
      <b-card-text>
        {{ dataProfile.jabatan2 }}
      </b-card-text>
    </div>
    <div class="mt-2">
      <h5 class="text-capitalize mb-75">
        Grade
      </h5>
      <b-card-text>
        {{ dataProfile.grade }}
      </b-card-text>
    </div>
  </b-card>
</template>
<script>
import { BCard, BCardTitle, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardText,
  },
  props: {
    dataProfile: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
