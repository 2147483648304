<template>
  <b-card>
    <!-- about
    <div
      v-for="(data,key,index) in dataProfile in 10"
      :key="data.nip"
      :class="index ? 'mt-2':''"
    > -->
    <b-card-title>Alamat Homebase</b-card-title>
    <div class="mt-2">
      <h5 class="text-capitalize mb-75">
        Alamat
      </h5>
      <b-card-text>
        {{ dataProfile.retired_jalan }}
      </b-card-text>
      <b-card-text>
        RT. {{ dataProfile.retired_rt }} RW. {{ dataProfile.retired_rw }}
      </b-card-text>
    </div>
    <div class="mt-2">
      <h5 class="text-capitalize mb-75">
        Kelurahan
      </h5>
      <b-card-text>
        {{ dataProfile.retired_kelurahan }}
      </b-card-text>
    </div>
    <div class="mt-2">
      <h5 class="text-capitalize mb-75">
        Kecamatan
      </h5>
      <b-card-text>
        {{ dataProfile.retired_kecamatan }}
      </b-card-text>
    </div>
    <div class="mt-2">
      <h5 class="text-capitalize mb-75">
        Kabupaten
      </h5>
      <b-card-text>
        {{ dataProfile.retired_kabupaten }}
      </b-card-text>
    </div>
    <div class="mt-2">
      <h5 class="text-capitalize mb-75">
        Provinsi
      </h5>
      <b-card-text>
        {{ dataProfile.retired_provinsi }}
      </b-card-text>
    </div>
    <div class="mt-2">
      <h5 class="text-capitalize mb-75">
        Kode Pos
      </h5>
      <b-card-text>
        {{ dataProfile.retired_kodepos }}
      </b-card-text>
    </div>
  </b-card>
</template>
<script>
import { BCard, BCardTitle, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardText,
  },
  props: {
    dataProfile: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
