<template>
  <b-card>
    <!-- about
    <div
      v-for="(data,key,index) in dataProfile in 10"
      :key="data.nip"
      :class="index ? 'mt-2':''"
    > -->
    <b-card-title>Ciri Pribadi</b-card-title>
    <div class="mt-2">
      <h5 class="text-capitalize mb-75">
        Golongan Darah
      </h5>
      <b-card-text>
        {{ dataProfile.golongan_darah }}
      </b-card-text>
    </div>
    <div class="mt-2">
      <h5 class="text-capitalize mb-75">
        Warna Rambut
      </h5>
      <b-card-text>
        {{ dataProfile.rambut }}
      </b-card-text>
    </div>
    <div class="mt-2">
      <h5 class="text-capitalize mb-75">
        Warna Kulit
      </h5>
      <b-card-text>
        {{ dataProfile.kulit }}
      </b-card-text>
    </div>
    <div class="mt-2">
      <h5 class="text-capitalize mb-75">
        Tinggi Badan
      </h5>
      <b-card-text>
        {{ dataProfile.tinggi_badan }} cm
      </b-card-text>
    </div>
    <div class="mt-2">
      <h5 class="text-capitalize mb-75">
        Berat Badan
      </h5>
      <b-card-text>
        {{ dataProfile.berat_badan }} kg
      </b-card-text>
    </div>
    <div class="mt-2">
      <h5 class="text-capitalize mb-75">
        Ciri Lain
      </h5>
      <b-card-text>
        {{ dataProfile.ciri_lain }}
      </b-card-text>
    </div>
  </b-card>
</template>
<script>
import { BCard, BCardTitle, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardText,
  },
  props: {
    dataProfile: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
