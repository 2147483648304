<template>
  <b-card>
    <!-- about
    <div
      v-for="(data,key,index) in dataProfile in 10"
      :key="data.nip"
      :class="index ? 'mt-2':''"
    > -->
    <b-card-header>
      <b-card-title>Data Pribadi</b-card-title>
      <feather-icon
        icon="EditIcon"
        size="18"
        class="text-muted cursor-pointer"
      />
    </b-card-header>
    <div class="mt-2">
      <h5 class="text-capitalize mb-75">
        Tempat, Tanggal Lahir
      </h5>
      <b-card-text>
        {{ dataProfile.tempat_lahir }}, {{ this.$moment(dataProfile.tanggal_lahir).format('DD MMMM YYYY') }}
      </b-card-text>
    </div>
    <div class="mt-2">
      <h5 class="text-capitalize mb-75">
        Usia
      </h5>
      <b-card-text>
        {{ dataProfile.usia_detil }}
      </b-card-text>
    </div>
    <div class="mt-2">
      <h5 class="text-capitalize mb-75">
        Jenis Kelamin
      </h5>
      <b-card-text>
        {{ dataProfile.sex }}
      </b-card-text>
    </div>
    <div class="mt-2">
      <h5 class="text-capitalize mb-75">
        Agama
      </h5>
      <b-card-text>
        {{ dataProfile.agama }}
      </b-card-text>
    </div>
    <div class="mt-2">
      <h5 class="text-capitalize mb-75">
        Status Pernikahan
      </h5>
      <b-card-text>
        {{ dataProfile.status_pernikahan }}
      </b-card-text>
    </div>
    <div class="mt-2">
      <h5 class="text-capitalize mb-75">
        Hobby
      </h5>
      <b-card-text>
        {{ dataProfile.hobby }}
      </b-card-text>
    </div>
  </b-card>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
  },
  props: {
    dataProfile: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
