<template>
  <b-card
    :img-src="require('@/assets/images/banner/banner-djpb.png')"
    img-alt="Profile Cover Photo"
    img-top
    class="card-profile"
  >
    <div class="profile-image-wrapper">
      <div class="profile-image p-0">
        <b-avatar
          size="114"
          variant="light"
          :src="`${dataProfile.foto}`"
        />
      </div>
    </div>
    <h3>{{ `${dataProfile.nama}` }}</h3>
    <b-badge
      class="profile-badge"
      variant="light-primary"
    >
      NIP: {{ `${dataProfile.nip}` }}
    </b-badge>

  </b-card>
</template>

<script>
import { BCard, BAvatar, BBadge } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BBadge,
    BAvatar,
  },
  props: {
    dataProfile: {
      type: Object,
      default: () => {},
    },
  },

}
</script>
